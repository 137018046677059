.quiz {
  border-radius: 10px;
  padding: 25px 40px;
  max-width: 740px;
  margin: 40px auto;
  background: #fff;

  .user-content {
    display: flex;
    justify-content: space-between;
    .name {
      display: flex;
      align-items: center;
    }
    .logo {
      margin: -25px 0;
    }
  }
  .quiz-content {
    margin: 0px 40px;
    .quantity {
      color: #cccbcb;
      font-size: 14px;
    }
    .question-row {
      min-height: 50px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      .manager-avatar {
        max-width: 50px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        margin-right: 15px;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
      .question-text {
        font-size: 17px;
      }
    }

    .item {
      margin-bottom: 20px;
      border: 1px solid #d3d3d3;
      cursor: pointer;
      .item-choose {
        background: #fff;
        text-transform: uppercase;
        font-size: 23px;
        line-height: 1;
        font-weight: 600;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-text {
        background: #d3d3d3;
        font-size: 14px;
        width: calc(100% - 30px);
        padding: 4px 15px;
        box-sizing: border-box;
      }

      &.correct,
      &.selected.correct {
        border: 1px solid transparent;
        animation: blinker1 0.2s linear 3;
        .item-choose {
          background: #d3d3d37d;
          span {
            opacity: 0;
          }
          &:after {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            background: url(./assets/checkbox_correct.png) no-repeat center
              center;
            background-size: cover;
            opacity: 0.8;
          }
        }
        .item-text {
          background: #4df94d94;
        }
      }
      &.error,
      &.selected.error {
        border: 1px solid transparent;
        .item-choose {
          background: #d3d3d37d;
          span {
            opacity: 0;
          }
          &:after {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            background: url(./assets/checkbox_error.png) no-repeat center center;
            background-size: cover;
            opacity: 0.8;
          }
        }
        .item-text {
          background: #f88686;
        }
      }
      &.selected {
        .item-choose {
          background: #d3d3d37d;
        }
        .item-text {
          background: #ffbe46;
        }
      }
    }
    .gray-btn {
      background: #1aa1b1 0% 0% no-repeat padding-box;
      border: 0;
      width: 150px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #fff;
      transition: all 0.4s;
      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
    .correct-answer-text {
      min-height: 45px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;

      box-sizing: border-box;
      animation: blinker1 0.2s linear 3;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .correct-answer-text-inner {
        padding: 5px 10px;
        background: #4df94d94;
      }
    }
  }
  .bottom-content {
    .earned-block {
      span {
        display: flex;
        align-items: center;
        font-size: 15px;
      }
      .quantity {
        display: inline-block;
        font-size: 30px;
        font-weight: 700;
        margin-left: 15px;
      }
    }
    button {
      appearance: none;
      border: 0;
      background: transparent;
      color: #87389c;
      text-decoration: none;
      font-weight: 700;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@keyframes blinker1 {
  50% {
    opacity: 0;
  }
}
