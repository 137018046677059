.welcome {
  border-radius: 10px;
  padding: 25px 40px;
  max-width: 740px;
  margin: 40px auto;
  background: #fff;

  .logo {
    display: flex;
    justify-content: flex-end;
    margin: -25px 0;
  }
  .inner {
    padding: 35px;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    color: #373737;
    margin: 0 0 25px;
  }
  p,
  ul {
    font-size: 12px;
    font-weight: 400;
    color: #373737;
    margin: 0 0 15px;
    margin-right: 25px;
  }
  small {
    font-size: 50%;
  }

  .campaign-list {
    padding: 0 35px;
  }

  .buttons {
    text-align: center;
    button {
      font-family: 'Poppins', sans-serif;
      display: inline-block;
      appearance: none;
      border: none;
      width: 175px;
      height: 55px;
      background: #1aa1b1 0% 0% no-repeat padding-box;
      opacity: 1;
      font-size: 18px;
      color: #fff;
      margin: 0 10px;

      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
  }
}

.nothing-is-assigned {
  border-radius: 10px;
  padding: 25px 40px;
  max-width: 540px;
  margin: 40px auto;
  background: #fff;
  margin-top: 100px;

  .inner-content {
    h2 {
      font-size: 1.5625rem;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: #373737;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .buttons {
      padding: 20px 0;
      text-align: center;
      button {
        font-family: 'Poppins', sans-serif;
        display: inline-block;
        appearance: none;
        border: none;
        width: 175px;
        height: 55px;
        background: #1aa1b1 0% 0% no-repeat padding-box;
        opacity: 1;
        font-size: 18px;
        color: #fff;
        margin: 0 10px;

        &:hover {
          background: #40b2be;
        }
        &:disabled {
          cursor: initial;
          background: #a1a1a1;
        }
      }
    }
  }
}
