.upload-file {
  label {
    display: block;
    width: 100%;
    background: #e4e4e4;
    color: #373737;
    font-size: 12px;
    padding: 3px 10px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .upload-img-block {
    width: 100%;
    border: 1px solid #c7d3d3;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
