.containers {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
  box-sizing: border-box;
}
.admin-panel-container {
  background: #fff;
  border-radius: 10px;
  padding: 25px 40px;
  max-width: 960px;
  margin: 40px auto;
}

.admin-panel {
  .submit-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    button {
      font-family: 'Poppins', sans-serif;
      background: #1aa1b1;
      font-size: 12px;
      color: #fefefe;
      font-weight: 500;
      border: 0;
      text-decoration: none;
      display: inline-block;
      height: 21px;
      width: 91px;
      text-align: center;
      line-height: 20px;
      border-radius: 2px;
      box-sizing: border-box;
      margin: 0 0 0 20px;

      &:hover {
        background: #40b2be;
      }

      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }

      &.prev {
        svg {
          margin-left: -10px;
        }
      }
      &.next {
        svg {
          margin-right: -10px;
        }
      }
      svg {
        line-height: 21px;
        vertical-align: middle;
        font-size: 150%;
        margin-bottom: 2px;
      }
    }
  }
}
