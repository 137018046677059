.game-setup {
  margin: 0px 40px;
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin-left: -40px;
  }

  .choose-game {
    .radio-btn-group {
      label {
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 2px;
        span {
          padding-top: 2px;
        }
      }
    }
  }

  .prizes {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5%;

    .prize {
      width: 32%;
      margin: 0 0.6%;
      .inner-wrap {
        padding: 10px;
      }
      .inner-title {
        position: relative;
        background: #a1a1a1;
        color: #373737;
        font-size: 11px;
        line-height: 18px;
        display: inline-block;
        padding: 5px 15px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;

        button.remove {
          appearance: none;
          border: 0;
          background: transparent;
          position: absolute;
          padding: 0;
          cursor: pointer;
          right: 4px;
          top: 4px;
          svg {
            color: #373737;
            font-size: 20px;
          }
        }
      }
    }
  }
  .add-more-button {
    margin-bottom: 30px;
    button {
      font-family: 'Poppins', sans-serif;
      background: #1aa1b1;
      font-size: 12px;
      color: #fefefe;
      font-weight: 500;
      border: 0;
      text-decoration: none;
      display: inline-block;
      width: 176px;
      height: 33px;
      text-align: center;
      line-height: 20px;
      border-radius: 2px;
      box-sizing: border-box;
      margin: 0px 10px;

      &:hover {
        background: #40b2be;
      }

      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
  }

  .row-block {
    margin-bottom: 0px;
    flex-wrap: wrap;
    .w-1 {
      width: 24%;
    }
    .w-3 {
      width: 32%;
      margin: 0.5%;
    }
    .w-4 {
      width: 49%;
    }

    .company-name-block {
      width: 49%;
      margin-right: 2%;
      .campaign-name {
        display: block;
        width: 100%;
        height: 38px;
        padding: 0 10px;
        box-sizing: border-box;
        background: #e4e4e4 0% 0% no-repeat padding-box;
        border: 1px solid #a1a1a1;
        font-family: 'Poppins', sans-serif;
        font-size: 11px;

        &:disabled {
          background: #cecece;
        }
      }
    }
    .active-campaign-block {
      width: 24%;
      margin-right: 1%;
      h3 {
        margin-left: 0;
      }
    }
    .new-campaign-block {
      width: 24%;
      h3 {
        margin-left: 0;
      }
      button {
        font-family: 'Poppins', sans-serif;
        background: #1aa1b1;
        font-size: 12px;
        color: #fefefe;
        font-weight: 500;
        border: 0;
        text-decoration: none;
        display: inline-block;
        width: 176px;
        height: 38px;
        text-align: center;
        line-height: 20px;
        border-radius: 2px;
        box-sizing: border-box;
        margin: 0px 10px;

        &:hover {
          background: #40b2be;
        }

        &:disabled {
          cursor: initial;
          background: #a1a1a1;
        }
      }
    }

    .block {
      .inner-wrap {
        padding: 10px;
      }
      .inner-title {
        background: #a1a1a1;
        color: #373737;
        font-size: 11px;
        line-height: 18px;
        display: inline-block;
        padding: 5px 15px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
      }
    }
  }

  .quiz-smartSpin,
  .quiz-smartSpin-luck {
    .btn-block {
      margin-top: 0;
      margin-left: 10px;
      margin-bottom: 25px;
      justify-content: flex-start;
    }
  }

  .form-group {
    margin-top: 10px;
    label {
      display: block;
      width: 100%;
      background: #e4e4e4;
      color: #373737;
      font-size: 12px;
      padding: 3px 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #c7d3d3;
      border-radius: 2px;
      padding: 0px 10px;
      height: 25px;
      line-height: 20px;
      font-size: 14px;
    }
  }
  .upload-file {
    .upload-img-block {
      height: 130px;
    }
    .border-for-img {
      border: 1px solid #c7d3d3;
      height: 100%;
      border-radius: 5px;
    }
  }
}
