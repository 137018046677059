.admin-steps {
  margin: 60px 40px;
  .item {
    padding: 0px 70px;
    position: relative;
    text-align: center;
    &.active {
      &:after {
        background: #56bdea;
      }
      .img {
        background: #56bdea;
      }
      .name {
        color: #56bdea;
      }
    }
    &.completed {
      &:after {
        background: #93bd26;
      }
      .img {
        background: #93bd26;
      }
      .name {
        color: #93bd26;
      }
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: #ebebeb;
      top: 25px;
      left: 0;
    }
    &:first-child {
      padding-left: 0;
      text-align: left;
    }
    &:last-child {
      padding-right: 0;
      text-align: right;
    }
    a {
      text-decoration: none;
    }
    .img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #ebebeb;
      display: inline-block;
      text-align: center;
      line-height: 50px;
      z-index: 9;
      position: relative;
      margin-bottom: 10px;
      color: #fff;
      font-size: 25px;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      i.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        font-weight: 600;
        font-style: normal;
      }
    }
    .name {
      display: block;
      text-align: center;
      font-size: 13px;
      color: #cdd9d9;
      font-weight: 700;
    }
    .completed {
      opacity: 0;
      background: #93bd26;
      color: #fff;
      font-size: 9px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
      margin-left: -13px;
      margin-right: 3px;
      line-height: 10px;
      text-align: center;
    }
  }
  .item.completed {
    .completed {
      opacity: 1;
    }
  }
}
