.login {
  height: 100%;
  min-height: 100vh;
  flex-direction: column;

  .inner-content {
    padding: 40px 40px 25px;
    border-radius: 10px;
    background: #fff;
    min-width: 270px;
    position: relative;
    h2 {
      font-size: 1.5625rem;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: #373737;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .main-logo {
      position: absolute;
      width: 299px;
      height: 299px;
      left: -145px;
      top: -160px;
    }
  }
  .form-checkbox {
    label {
      color: #a1a1a0;
      font-size: 10px;
      line-height: 15px;
      display: inline-block;
      vertical-align: middle;
    }
    input {
      border: 1px solid #a1a1a0;
      color: #a1a1a0;
      width: 10px;
      margin: 0;
      margin-right: 10px;
      line-height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .form-group {
    margin-bottom: 1rem;
    label {
      font-size: 0.75rem;
      display: block;
      font-weight: 600;
      margin-bottom: 0.4375rem;
      background: none;
      padding: 0;
    }
    .input {
      position: relative;
    }
    .icon {
      color: #d3d3d3;
      position: absolute;
      top: 6px;
      left: 2px;
    }
    input {
      border: 0px;
      border-bottom: 1px solid #d3d3d3;
      padding: 0.4rem 1.5rem;
      width: 100%;
      box-sizing: border-box;
      transition: all 0.4s;
      &:focus {
        transition: all 0.4s;
        border-bottom: 1px solid #43689e;
      }
      &::placeholder {
        color: #d3d3d3;
        font-size: 0.75rem;
      }
    }
  }
  .login-btn {
    text-align: center;
    letter-spacing: 0.12px;
    color: #fefefe;
    font-size: 0.75rem;
    width: 100%;
    height: 27px;
    text-align: center;
    line-height: 27px;
    background: #1aa1b1;
    border: 0px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    &:hover {
      background: #40b2be;
    }
    &:disabled {
      cursor: initial;
      background: #a1a1a1;
    }
  }
  .forgot-pass {
    color: #a1a1a0;
    font-size: 0.625rem;
    text-decoration: none;
    background: none;
    border: 0;
    float: right;

    &:hover {
      color: #797979;
    }
  }
  .error {
    font-size: 10px;
    color: red;
    font-style: italic;
  }
}

.thankyou-dialog {
  box-sizing: border-box;
  padding: 30px;
  max-width: 440px;
  text-align: center;
  padding: 70px 0;
  .thankyou-title {
    font-size: 36px;
    line-height: 55px;
    margin: 0 0 10px;
    padding: 0;
  }

  .thankyou-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
