.main-logo {
  width: 8.75rem;
  height: 8.75rem;
  position: relative;
  top: 1rem;
  left: 1rem;

  img {
    max-width: 100%;
  }
}
