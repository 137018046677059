.press-your-luck {
  width: 770px;
  height: 580px;
  background: #575757 0% 0% no-repeat;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 10px;

  .board {
    display: flex;
    flex-direction: column;

    & > .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > .column {
        display: flex;
        flex-direction: column;

        &.middle {
          flex-grow: 1;
          padding: 10px;
        }
      }
    }
  }

  .cell {
    box-sizing: border-box;
    width: 115px;
    height: 103px;
    margin: 3px;
    background-color: red;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 10px;
    border: 7px solid transparent;

    img {
      width: 60%;
      display: block;
    }

    &.brand-logo {
      background: #fafafb 0% 0% no-repeat;
      border: 7px solid #040769;
      img {
        width: 80%;
      }
    }

    &.prize1 {
      background: #b0188a 0% 0% no-repeat;
      border: 7px solid #5e0551;
    }
    &.prize2 {
      background: #f58c1f 0% 0% no-repeat;
      border: 7px solid #be4405;
    }
    &.prize3 {
      background: #1198a7 0% 0% no-repeat;
      border: 7px solid #056b77;
    }
    &.whammy {
      background: #c21f3a 0% 0% no-repeat;
      border: 7px solid #81051a;
    }
    &.active {
      border: 7px solid #fff;
      transform: scale(1.2);
      z-index: 1;
    }
  }

  .center {
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-sizing: border-box;

    .logo-container {
      padding: 5px 10px;
      height: 145px;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #000;
      text-transform: uppercase;
      word-spacing: 100vw;
      font-family: 'Poppins', sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .game-stop {
      box-sizing: border-box;
      appearance: none;
      border: 0;
      display: block;
      margin: 0 auto;
      width: 155px;
      height: 155px;
      background: url('./assets/button.png') no-repeat;
      background-size: contain;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      text-transform: uppercase;
      word-spacing: 100vw;
      font-family: 'Poppins', sans-serif;
      animation: blinker2 1s linear infinite;
    }
  }
}

.lose-press-dialog {
  background-color: #d81616;

  padding: 50px 30px;
  border: 10px solid #81051a;
  border-radius: 10px;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: -5px;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);

  .lose-dialog-title {
    font-size: 45px;
    margin: 0 0 10px;
  }
  .lose-dialog-description {
    font-size: 36px;
    margin: 0 0 10px;
  }

  .lose-dialog-buttons {
    text-align: center;
    width: 100%;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      color: #fff;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      background: #a1a1a1 0% 0% no-repeat;
      margin: 0 5px;
    }
  }
}

.win-dialog {
  background-color: #d81616;
  box-sizing: border-box;
  padding: 30px;
  border: 10px solid #81051a;
  border-radius: 10px;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  padding-bottom: 100px;

  &.brand-logo-color {
    background: #fafafb 0% 0% no-repeat;
    border: 7px solid #040769;
    .win-dialog-title {
      color: #000;
    }
  }

  &.prize1 {
    background: #b0188a 0% 0% no-repeat;
    border: 7px solid #5e0551;
  }
  &.prize2 {
    background: #f58c1f 0% 0% no-repeat;
    border: 7px solid #be4405;
  }
  &.prize3 {
    background: #1198a7 0% 0% no-repeat;
    border: 7px solid #056b77;
  }

  .win-dialog-title {
    font-size: 60px;
    font-weight: normal;
    margin: 0 0 10px;
  }

  .win-dialog-image {
    width: 100%;
    height: 300px;

    img {
      display: block;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .win-dialog-buttons {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      color: #fff;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      background: #a1a1a1 0% 0% no-repeat;
      margin: 0 5px;
    }
  }
}

.press-your-luck-row {
  display: flex;
  margin: 50px auto;
  justify-content: center;
  max-width: 1280px;
}

.press-your-luck-prizes {
  width: 450px;
  height: 580px;
  margin-left: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-shrink: 0;

  .prize {
    box-sizing: border-box;
    border-radius: 10px;
    height: 137px;
    display: flex;
    padding: 20px;

    &.brand-logo-color {
      background-color: #fff;
      .prize-content {
        h3,
        p {
          color: #000;
        }
      }
    }
    &.prize1 {
      background-color: #b0199a;
    }
    &.prize2 {
      background-color: #f58c1f;
    }
    &.prize3 {
      background-color: #1198a7;
    }

    .prize-content {
      flex-grow: 1;
      box-sizing: border-box;
      padding-right: 20px;

      h3 {
        margin: 0 0 10px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        font-weight: normal;
        color: #fff;
      }

      p {
        font-size: 11px;
        line-height: 14px;
        font-family: 'Poppins', sans-serif;
        margin: 0;
        font-weight: normal;
        color: #fff;
        height: 56px;
        overflow: hidden;
      }
    }
    .prize-image {
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      flex-shrink: 0;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .press-your-luck {
    width: 600px;
    height: 460px;
    .cell {
      width: 85px;
      height: 80px;
    }
    .center {
      .logo-container {
        height: 120px;
      }
      .game-stop {
        width: 110px;
        height: 110px;
      }
    }
  }
  .press-your-luck-prizes {
    width: 330px;
    height: 460px;

    .prize {
      padding: 10px;
      height: 107px;
      .prize-image {
        width: 87px;
        height: 87px;
      }
    }
  }
}

@keyframes blinker2 {
  50% {
    transform: scale(1.1);
  }
}
