.containers {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
  box-sizing: border-box;
  min-width: 1100px;
}

.inner-content.white {
  background: #fff;
}

.quit-dialog {
  box-sizing: border-box;
  padding: 30px;
  max-width: 440px;
  text-align: center;
  .quit-dialog-title {
    font-size: 45px;
    line-height: 55px;
    margin: 0 0 10px;
    padding: 0;
  }
  .quit-dialog-title2 {
    font-size: 36px;
    line-height: 45px;
    margin: 0 0 30px;
    padding: 0;
  }
  .quit-dialog-text {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
    margin: 0 0 35px;
    padding: 0;
  }
  .quit-dialog-buttons {
    text-align: center;
    width: 100%;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      color: #fff;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      margin: 0 5px;
      background: #1aa1b1 0% 0% no-repeat padding-box;
      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
  }
}
