.radio-btn-group {
  display: flex;
  align-items: center;
  input {
    width: 18px;
    height: 18px;
    margin: 0;
  }
  label {
    color: #373737;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .text-label {
    margin-left: 10px;
  }
}
