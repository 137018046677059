.report-content {
  .css-i0syzg-menu{
    background: #EEE;
  }
  .active-campaign-block {
    display: flex;

    margin-bottom: 30px;
    justify-content: flex-start;
    align-items: center;
    h3 {
      margin-right: 10px;
    }
    .select-wrapper {
      width: 300px;
    }
  }
  .charts-block {
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    .block {
      width: 40%;
      text-align: center;
      .title {
        color: #717171;
        font-size: 12px;
        margin: 10px 0px;
      }
      .chart1 {
        span {
          left: 6px;
        }
      }
      .chart2 {
        span {
          left: 56px;
        }
      }
      .chart {
        position: relative;
        align-items: center;
        justify-content: center;
        span {
          position: absolute;
          color: #717171;
          font-size: 10px;
          margin-right: 5px;
        }
      }
      .info {
        justify-content: center;
        .block {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
        .blue {
          .square {
            background: #b31c9d;
          }
        }
        .green {
          .square {
            background: #1399a9;
          }
        }
        .orange {
          .square {
            background: #1399a9;
          }
        }
        span {
          color: #373737;
          font-size: 11px;
          vertical-align: middle;
          display: inline-block;
        }
        .square {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }
  .report-info {
    .report-title {
      margin: 25px 0px;
      overflow: hidden;
      .item {
        background: #d3d3d3;
        color: #717171;
        font-size: 12px;
        border-radius: 2px;
        border: 0;
        padding: 7px 28px;
        margin-right: 10px;
        &:hover {
          background: #c1bdbd;
        }
        &.active {
          background: #56bdea;
          color: #fff;
        }
      }
      .download-report {
        background: #e4e4e4;
        font-size: 12px;
        color: #373737;
        font-weight: 500;
        border: 0;
        text-decoration: none;
        display: block;
        height: 25px;
        width: auto;
        text-align: center;
        line-height: 25px;
        border-radius: 2px;
        box-sizing: border-box;
        margin: 0px 6px;
        padding: 0px 30px;
        cursor: pointer;
        float: right;
        margin-top: 3px;
        &:hover {
          background: #c1bdbd;
        }
        &:disabled {
          opacity: 0.7;
          cursor: auto;
          &:hover {
            background: #e4e4e4;
          }
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .table-report {
      table {
        width: 100%;
        border-collapse: collapse;
        font-size: 12px;
      }
      th {
        background: #a1a1a1;
        color: #373737;
        text-align: left;
        padding: 5px 10px;
        border-bottom: 1px solid #a1a1a1;
        border-right: 1px solid #a1a1a1;
        border-top: 1px solid #a1a1a1;
        &:first-child {
          border-left: 1px solid #a1a1a1;
        }
        &.transparent {
          background-color: transparent;
          border-color: transparent;
        }
      }
      td {
        border-bottom: 1px solid #a1a1a1;
        border-right: 1px solid #a1a1a1;
        padding: 5px 10px;
        box-sizing: border-box;
        &:first-child {
          border-left: 1px solid #a1a1a1;
        }

        .view-more {
          appearance: none;
          border: 0;
          background: transparent;
          font-size: 12px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.MuiBackdrop-root {
  transition: none!important;
}
.MuiDialog-container{
  transition: none!important;
}
.MuiPaper-root {
  background: #000;
  transition: none!important;
}

.questions-report-dialog {
  width: 550px;
  max-height: 600px;
  position: relative;
  box-sizing: border-box;
  padding: 30px 70px;
  background: #fff;

  .close-dialog {
    position: absolute;
    right: 10px;
    top: 10px;
    appearance: none;
    border-radius: 50%;
    border: 1px solid #a1a1a1;
    color: #a1a1a1;
    background: transparent;
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 30px;
    font-size: 20px;
  }

  .questions-report-dialog-title {
    margin: 0 0 10px;

    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
  p {
    font-size: 12px;
    margin: -5px 0 20px;
  }
  .table-report {
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 12px;
    }
    th {
      background: #a1a1a1;
      color: #373737;
      text-align: left;
      padding: 5px 10px;
      border-bottom: 1px solid #a1a1a1;
      border-right: 1px solid #a1a1a1;
      border-top: 1px solid #a1a1a1;
      &:first-child {
        border-left: 1px solid #a1a1a1;
      }
    }
    td {
      border-bottom: 1px solid #a1a1a1;
      border-right: 1px solid #a1a1a1;
      padding: 5px 10px;
      box-sizing: border-box;
      &:first-child {
        border-left: 1px solid #a1a1a1;
      }

      &.correct {
        width: 60px;
        background-color: #93bd26;
        text-align: center;
      }
      &.incorrect {
        width: 60px;
        background-color: #f43838;
        text-align: center;
      }
    }
  }
  .report-button {
    text-align: center;
    padding: 20px 0 0;
  }
  .download-report {
    background: #e4e4e4;
    font-size: 12px;
    color: #373737;
    font-weight: 500;
    border: 0;
    text-decoration: none;
    display: inline-block;
    height: 25px;
    width: auto;
    text-align: center;
    line-height: 25px;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0px 6px;
    padding: 0px 30px;
    cursor: pointer;
    &:hover {
      background: #c1bdbd;
    }
    &:disabled {
      opacity: 0.7;
      cursor: auto;
      &:hover {
        background: #e4e4e4;
      }
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
