.change-password {
  flex-direction: column;

  .inner-content {
    padding: 25px 40px 40px;
    border-radius: 10px;
    background: #fff;
    min-width: 270px;
    h2 {
      font-size: 1.5625rem;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: #373737;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  .form-group {
    margin-bottom: 1rem;
    label {
      font-size: 0.75rem;
      display: block;
      font-weight: 600;
      margin-bottom: 0.4375rem;
      background: none;
      padding: 0;
    }
    .input {
      position: relative;
    }
    .icon {
      color: #d3d3d3;
      position: absolute;
      top: 6px;
      left: 2px;
    }
    input {
      border: 0px;
      border-bottom: 1px solid #d3d3d3;
      padding: 0.4rem 1.5rem;
      width: 100%;
      box-sizing: border-box;
      transition: all 0.4s;
      &:focus {
        transition: all 0.4s;
        border-bottom: 1px solid #43689e;
      }
      &::placeholder {
        color: #d3d3d3;
        font-size: 0.75rem;
      }
    }
    .error {
      font-size: 10px;
      color: red;
      font-style: italic;
    }
  }
  button {
    text-align: center;
    letter-spacing: 0.12px;
    color: #fefefe;
    font-size: 0.75rem;
    width: 100%;
    height: 27px;
    text-align: center;
    line-height: 27px;

    border: 0px;
    margin-top: 1rem;
    margin-bottom: 0rem;
    cursor: pointer;

    &.submit {
      background: #1aa1b1;
      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }

    &.cancel {
      background: #a1a1a1;
      &:hover {
        background: #aaaaaa;
      }
      &:disabled {
        background: #d1d1d1;
      }
    }
  }
}
