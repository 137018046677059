.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.datePicker {
  width: 100% !important;
}

.field-component {
  flex-grow: 1;
  .error-field {
    color: red !important;
    border-color: red !important;
  }
  .error-helper-text {
    font-size: 10px;
    padding: 3px;
    color: red;
    font-style: italic;
  }
}

.custom-date-picker {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  margin: 0;
  padding: 5px;
  text-align: center;
  display: block;
  width: 100%;
  max-width: 100%;
  border: 0;
  background: #e4e4e4;
  color: #373737;
  box-sizing: border-box;
}

.form-group {
  .textarea {
    position: relative;
    textarea {
      font-family: 'Poppins', sans-serif;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #c7d3d3;
      border-radius: 2px;
      padding: 5px;
      height: 70px;
      line-height: 1.2;
      font-size: 14px;
      resize: none;
      display: block;
    }
  }
}
.radio-btn-group {
  display: block;
}
