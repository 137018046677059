.admin-header {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .name {
    font-size: 25px;
    font-weight: 700;
    color: #373737;
    display: inline-block;
    margin-right: 25px;
  }
  .change-pass {
    appearance: none;
    border: 0;
    background: transparent;
    color: #373737;
    font-size: 11px;
    padding: 0px 20px;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    &:hover {
      color: #8a349c;
    }
  }
  .log-off {
    appearance: none;
    border: 0;
    background: transparent;
    color: #373737;
    font-size: 11px;
    padding: 0px 20px;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    &:hover {
      color: #8a349c;
    }
  }

  .links {
  }

  .logo {
    display: flex;
    justify-content: flex-end;
    margin: -45px 0;
  }
}
