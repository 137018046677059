.brand-logo {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  margin: 5px 0;

  img {
    border: 0;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
