.branding-content {
  margin: 0px 40px;
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  .row-block {
    margin-bottom: 0px;
    flex-wrap: wrap;
    .w-1 {
      width: 26%;
    }
    .w-3 {
      width: 32%;
    }

    .block {
      .inner-wrap {
        padding: 10px;
      }
      .inner-title {
        background: #a1a1a1;
        color: #373737;
        font-size: 11px;
        line-height: 18px;
        display: inline-block;
        padding: 5px 15px;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
      }
    }
  }
  .choose-theme {
    .text-label {
      min-width: 120px;
    }
  }
  .upload-file {
    .upload-img-block {
      height: 150px;
    }
  }
}
