.question-content {
  position: relative;
  margin: 0px 40px;
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin-left: -40px;
  }

  .inner-wrap {
    padding: 10px;
  }
  .inner-title {
    background: #a1a1a1;
    color: #373737;
    font-size: 11px;
    line-height: 18px;
    display: inline-block;
    padding: 5px 15px;
    box-sizing: border-box;
    width: 190px;
    text-align: left;
    margin-bottom: 7px;
  }
  .question-section1 {
    .delete-question {
      position: absolute;
      right: 0px;
      top: 0;
    }
    .btn-block {
      margin-top: 0px;
      justify-content: flex-start;
      margin-left: 10px;
    }
  }
  .question-section {
    .button-row {
      display: flex;
      justify-content: space-between;
      .switcher {
        button {
          background: #e4e4e4;
          color: #373737;
          font-size: 11px;
          line-height: 18px;
          display: inline-block;
          padding: 5px 15px;
          box-sizing: border-box;
          width: 190px;
          text-align: left;
          margin-bottom: 7px;
          border: 0;
          margin-right: 10px;

          &.active {
            background: #a1a1a1;
          }
          &:disabled {
            opacity: 0.7;
          }
        }
      }
    }
    .multi-choice {
      margin-left: 10px;
    }
    .true-false-choice {
      margin-left: 10px;
      .question {
        .form-group {
          width: 95%;
        }
      }
      .form-group {
        width: auto;
      }
    }
    .btn-block {
      margin-top: 20px;
      margin-left: 0px;
      margin-bottom: 25px;
      justify-content: flex-start;
      button {
        min-width: 125px;
      }
    }
    .form-group {
      display: flex;
      margin: 0px;

      label {
        margin-bottom: 0;
        width: 90px;
        margin-right: 5px;
        text-align: center;
        background: #e4e4e4;
        font-size: 12px;
        line-height: 25px;
      }
      .input {
        width: 88%;
        input {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #c7d3d3;
          border-radius: 2px;
          padding: 0px 10px;
          height: 25px;
          line-height: 20px;
          font-size: 14px;
        }
      }
    }
    .question {
      margin-bottom: 7px;
      label {
        background: #56bdea;
        color: #fff;
      }
      .error-helper-text {
        margin-left: 90px;
      }
    }
    .question-answer {
      margin-bottom: 7px;
      .error-helper-text {
        margin-left: 90px;
      }
      .radio-btn-group {
        margin: 4px 0 0 10px;
      }
    }
  }
  .green-btn {
    background: #93bd26;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    height: 30px;
    text-decoration: none;
    display: inline-block;
    padding: 5px 15px;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0px 6px;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background: #7c9e21;
    }
    &:disabled {
      opacity: 0.7;
      cursor: auto;
      color: #999;
      background: #e4e4e4;
      &:hover {
        background: #e4e4e4;
      }
    }
  }
  .red-btn {
    background: #f43838;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    height: 30px;
    text-decoration: none;
    display: inline-block;
    padding: 5px 15px;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0px 6px;
    &:disabled {
      opacity: 0.7;
      cursor: auto;
      color: #999;
      background: #e4e4e4;
      &:hover {
        background: #e4e4e4;
      }
    }
  }

  .gray-btn2 {
    background: #a1a1a1;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 0;
    height: 30px;
    text-decoration: none;
    display: inline-block;
    padding: 5px 15px;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0px 6px;
  }

  .gray-btn {
    background: #a1a1a1;
    font-size: 12px;
    color: #fefefe;
    font-weight: 500;
    border: 0;
    text-decoration: none;
    display: inline-block;
    height: 21px;
    width: 91px;
    text-align: center;
    line-height: 21px;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0px 6px;
    &:hover {
      background: #6b6b6b;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .gray-btn.light {
    background: #e4e4e4;
    color: #373737;
    width: auto;
    padding: 0px 30px;
    cursor: pointer;
    &:hover {
      background: #c1bdbd;
    }
    &:disabled {
      opacity: 0.7;
      cursor: auto;
      &:hover {
        background: #e4e4e4;
      }
    }
  }
  .btn-block {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    .back-btn {
      svg {
        margin-left: -5px;
        margin-right: 5px;
      }
    }
    .next-btn {
      margin-right: 0;
      svg {
        margin-right: -5px;
        margin-left: 5px;
      }
    }
  }

  .question-list {
    margin: 0 -40px;
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 12px;
    }
    th {
      background: #a1a1a1;
      color: #373737;
      text-align: left;
      padding: 5px 10px;
      border-bottom: 1px solid #a1a1a1;
      border-right: 1px solid #a1a1a1;
      border-top: 1px solid #a1a1a1;
      white-space: nowrap;
      &:first-child {
        border-left: 1px solid #a1a1a1;
      }
    }
    td {
      border-bottom: 1px solid #a1a1a1;
      border-right: 1px solid #a1a1a1;
      padding: 5px 10px;
      box-sizing: border-box;
      &:first-child {
        border-left: 1px solid #a1a1a1;
      }
      &.select-radio {
        text-align: center;
        vertical-align: middle;
        padding: 0;
        input {
          cursor: pointer;
          width: 18px;
          height: 18px;
          margin: 4px 0 0 0;
        }
      }
    }
  }
}
