.wheel-of-fortune {
  width: 100%;
  max-width: 625px;
  min-width: 460px;
  position: relative;

  .outer {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    border-radius: 50%;
    background-color: #f1a245;
    border: 10px solid #b4bfc4;
    padding: 20px;
    &:before {
      content: '';
      display: block;
      left: 10px;
      right: 10px;
      bottom: 10px;
      top: 10px;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #fff;
      padding: 7px;
    }
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  #wheelOfFortune {
    width: 100%;
    border-radius: 50%;
    background-color: #fff;
  }

  .inner {
    left: 30px;
    right: 30px;
    bottom: 30px;
    top: 30px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #fff;
  }

  .spin-button {
    width: 17%;
    height: 17%;
    line-height: 80px;
    appearance: none;
    box-sizing: border-box;
    border: 5px solid #c67b35;
    border-radius: 50%;
    background: white;
    font-weight: 800;
    font-family: Arial;
    font-size: 25px;
    color: #4a474a;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.ready {
      animation: blinker 1s linear infinite;
    }
  }

  .spin-button:focus {
    outline: 0;
  }
  .arrow {
    position: absolute;
    width: 18%;
    right: 4%;
    top: 4%;
    z-index: 999;
  }
}

@keyframes blinker {
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
