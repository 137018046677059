.quiz-end-dialog {
  box-sizing: border-box;
  padding: 30px;
  max-width: 440px;
  min-width: 440px;
  text-align: center;
  .points {
    font-size: 72px;
    text-align: center;
    line-height: 80px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .description {
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .quiz-end-dialog-buttons {
    text-align: center;
    width: 100%;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      background: #1aa1b1 0% 0% no-repeat padding-box;
      margin: 0 5px;
      color: #fff;
      transition: all 0.4s;
      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
  }
}
