.spin {
  margin: 40px auto 140px;
  position: relative;
  display: flex;
  .placer {
    position: absolute;
    width: 115%;
    top: -10%;
  }
  .game {
    width: 45%;
    position: relative;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .description {
    width: 55%;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    padding: 25px 40px 0 120px;
    max-width: 740px;
    width: 100%;

    .description-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .name {
        font-size: 25px;
        font-weight: 700;
        color: #373737;
      }
      .logo {
        width: 150px;
        height: 150px;
        margin: -25px 0 -10px;
      }
    }
    .score {
      display: flex;
      justify-content: center;
      align-items: center;

      .points {
        border: 1px solid #d3d3d3;
        border-radius: 10px;
        margin: 0 5px 30px;
        box-sizing: border-box;
        padding: 0 40px;
        text-align: center;
        .count {
          font-size: 30px;
          line-height: 50px;
          padding: 20px 10px;
          font-weight: 700;
          color: #373737;
        }
        .type {
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          padding: 10px;
          text-align: center;
          letter-spacing: 0.12px;
          color: #d3d3d3;
          border-top: 1px solid #d3d3d3;
        }
      }
    }

    .prizes {
      border: 1px solid #d3d3d3;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      table {
        width: 100%;

        border-collapse: collapse;
        tr {
          &:nth-child(even) {
            background-color: #f0f0f0;
          }

          &.highlighted {
            background: #ffbe46;
          }

          td {
            padding: 0 10px;
            font-size: 12px;
            font-weight: 500;
            height: 30px;
            vertical-align: middle;
            letter-spacing: 0.12px;

            &:first-child {
              text-align: center;
              padding: 0 0 0 10px;
            }

            &:last-child {
              text-align: right;
              font-size: 20px;

              font-weight: 700;
              color: #373737;
            }
          }
        }
        .icon-button {
          appearance: none;
          cursor: pointer;
          background: transparent;
          border: 0;
        }
      }
    }

    .links {
      text-align: right;
      padding: 10px 0;
      .log-off {
        font-family: 'Poppins', sans-serif;
        appearance: none;
        border: 0;
        background: transparent;
        color: #373737;
        font-size: 12px;
        line-height: 20px;
        text-decoration: none;
        font-weight: 700;
        &:hover {
          color: #8a349c;
        }
      }
    }

    .sound {
      position: absolute;
      right: 35px;
      bottom: -40px;
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      line-height: 25px;
      color: #d3d3d3;
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;
      span {
        vertical-align: top;
        margin: 0 3px;
      }
    }
  }
  .logo-points {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    .logo-points-image {
      width: 70px;
      img {
        display: block;
      }
    }
    .logo-points-label {
      margin-left: 10px;
    }
  }
}

.spin-dialog {
  box-sizing: border-box;
  padding: 30px;
  max-width: 440px;
  text-align: center;
  .spin-dialog-title {
    font-size: 45px;
    line-height: 55px;
    margin: 0 0 10px;
    padding: 0;
  }
  .spin-dialog-title2 {
    font-size: 36px;
    line-height: 45px;
    margin: 0 0 30px;
    padding: 0;
  }
  .spin-dialog-text {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
    margin: 0 0 35px;
    padding: 0;
  }
  .spin-dialog-buttons {
    text-align: center;
    width: 100%;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      color: #fff;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      margin: 0 5px;
      transition: all 0.4s;
      background: #1aa1b1 0% 0% no-repeat padding-box;
      &:hover {
        background: #40b2be;
      }
      &:disabled {
        cursor: initial;
        background: #a1a1a1;
      }
    }
  }
}

.prize-dialog {
  background-color: #514f51;
  box-sizing: border-box;
  padding: 30px;
  max-width: 440px;
  color: #fff;
  position: relative;

  .prize-dialog-close {
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    color: #b0b0af;
  }
  .prize-dialog-name {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 10px;
  }
  .prize-dialog-description {
    font-size: 11px;
    margin: 0 0 10px;
  }

  .prize-dialog-image {
    padding: 10px 0 0 0;
    img {
      width: 100%;
    }
  }
}

.lose-spin-dialog {
  //background-color: #d81616;
  background-color: #514f51;

  padding: 50px 30px;
  //border: 10px solid #81051a;

  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;

  .lose-dialog-title {
    font-size: 28px;
    margin: 0 0 10px;
  }
  .lose-dialog-description {
    font-size: 28px;
    margin: 0 0 10px;
  }

  .lose-dialog-buttons {
    text-align: center;
    width: 100%;
    button {
      font-family: 'Poppins', sans-serif;
      appearance: none;
      border: 0;
      color: #fff;
      font-size: 16px;
      line-height: 55px;
      width: 175px;
      height: 55px;
      background: #a1a1a1 0% 0% no-repeat;
      margin: 0 5px;
    }
  }
}
