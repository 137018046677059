body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
html,
body,
#root {
  height: 100%;
}
img {
  max-width: 100%;
}
.content {
  margin: 0 auto;
  min-height: 100vh;
  overflow-y: hidden;
  &.full {
    background: #0b91a0;
    background: -moz-linear-gradient(45deg, #0b91a0 0%, #b0199a 100%);
    background: -webkit-linear-gradient(45deg, #0b91a0 0%, #b0199a 100%);
    background: linear-gradient(45deg, #0b91a0 0%, #b0199a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b91a0', endColorstr='#b0199a',GradientType=1 );
  }
  &.light {
    background: #e7e7e7;
  }
  &.dark {
    background: #2a2a2a;
  }
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
button {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

a {
  transition: all 0.5s;
  &:hover {
    transition: all 0.5s;
  }
}

button {
  transition: all 0.5s;
  &:hover {
    transition: all 0.5s;
  }
  &:focus {
    outline: none;
  }
}

input {
  &:focus {
    outline: none;
  }
}
